import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryMgmtService {

  domain = environment.URL_Node;
  constructor(private http: HttpClient) { }

  private responseMessage = new BehaviorSubject('');
  private errorMessage = new BehaviorSubject('');
  currentResponseMessage = this.responseMessage.asObservable();
  currentErrorMessage = this.errorMessage.asObservable();

  changeResponseMessage(message: string) {
    this.responseMessage.next(message);

  }
  changeErrorMessage(message: string) {
    this.errorMessage.next(message);
  }

  clearMessages(time){
    setTimeout(()=>{
      this.responseMessage.next('');
      this.errorMessage.next('');
    },time)
   
  }




  assignInventory(data,operationType):Observable<any>{
    data.token = localStorage.getItem('authToken')
    if(operationType=="ASSIGN"){
      return this.http.post(this.domain + '/api/RMS/assignInventory', data)    
    }
    else if(operationType=='RETURN'){
      return this.http.post(this.domain + '/api/RMS/returnInventory', data)     
    }else {
      data.orgId=undefined;
      return this.http.post(this.domain + '/api/RMS/shiftInventory', data)        
    }
   
  }

  
}
